.card-img-top{
  object-fit: cover;
  height: 275px;
  /* width: 376px; */
  max-height: 275px;
  /* max-width: 376px;  */
}
.card-body, .card-title, .card-text, .fw-bold {
  color: black;
}
/* .App {
  text-align: center;
}*/
/* 
.App{
  border: 1px solid black;
  margin: auto;
  width: 50%;
  height: 200px;
  padding: 100px;
  overflow: scroll;
}  */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swal2-backdrop-show{
  z-index:50000 !important;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey; 
  border-radius: 1px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(40, 24, 24); 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff0303; 
}


.chat-icon.active {
  background-color: #f1f1f1;
  color: #0084ff;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  padding: 0;
}

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0084ff;
  color: white;
  padding: 12px 17px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  transition: background-color 0.2s ease-in-out;
}

.chat-icon.active {
  background-color: #f1f1f1;
  color: #0084ff;
}

.chat-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  max-height: 80vh;
  height: 80vh;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #0084ff;
  color: white;
  font-size: 18px;
}

.header span {
  flex: 1; /* Make the Welcome to the Chat! text take up available space */
}

/* ChatPopup.css */
.messages {
  flex: 1;
  display: flex;
  flex-direction: column-reverse; /* Show new messages at the bottom */
  padding: 10px;
  overflow-y: auto;
}


.close-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  padding: 0;
}

.message {

  display: flex;
  flex-direction: column-reverse; /* Show new messages at the bottom */
  padding: 10px;
}

.bubble {
  display: inline-block; /* Allow the text to wrap when it reaches the end of the container */
  padding: 0px;
  border-radius: 10px;
  word-break: break-word; /* Break words at any character if necessary */
}

.user-message {
  align-self: flex-end;
  background-color: #0084ff; /* New: Update background color for sender message */
  color: white; /* New: Update text color for sender message */
}

.user-message::before {
  right: 10px;
  border-width: 0 10px 10px 0; /* New: Style the triangle for sender message */
  border-color: transparent #0084ff transparent transparent; /* New: Style the triangle for sender message */
}

.user-message .message-time{
  color:white;
  align-self: flex-end;
}
.receiver-message {
  align-self: flex-start;
  background-color: #f1f1f1; /* New: Update background color for receiver message */
}

.receiver-message::before {
  left: 10px;
  border-width: 10px 10px 0 0; /* New: Style the triangle for receiver message */
  border-color: #f1f1f1 transparent transparent transparent; /* New: Style the triangle for receiver message */
}



.message-time {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

.input-container input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.send-btn {
  background-color: #0084ff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
}

.send-btn i {
  margin-right: 5px;
}

.send-btn:hover {
  background-color: #0073e6;
}


.info-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.getUserInfo{
  padding: 20px;
}